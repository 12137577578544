<template>
    <div class="p_item d_flex">
        <div class="img_wrapper">
            <img :src="data.imgSrc ? data.imgSrc : imgSrc" alt="" />
        </div>
        <div
            @click="
                redirect({
                    path: 'news',
                    query: {
                        id: data.tNo,
                        type:data.type,
                        status: status,
                        startDate:data.startDate,
                        endDate:data.endDate,
                        title:data.title,
                        sub:data.sub,
                        timing:data.timing,
                        pcid:data.pcid
                    },
                })
            "
            class="detail_wrapper d_flex"
            :class="{ done: status === 'redeemed', invalid: valid === 'expired' }"
        >
            <h5>{{ data.title }}</h5>
            <h6>{{ data.sub }}</h6>
            <span class="due">票券到期日 {{ [data.endDate.slice(0,4),data.endDate.slice(4,6),data.endDate.slice(6,8)].join("/") }}</span>
        </div>
    </div>
</template>

<script>
import { getCouponImage } from '@/apis/api.js';


export default {
    name: 'CouponItem',
    props: {
        data: {
            type: Object,
        },
        status: {
            type: String,
        },
        valid: {
            type: String,
        },
    },
    data: () => ({
        imgSrc:'',
    }),
    created() {
        console.log("getCouponImage",this.$route.query,this.data);
        var parent = this;
        getCouponImage(this.data.tNo).then(function(image_url){
            parent.imgSrc = image_url
        })
        if(parent.$route.query.tNo == this.data.tNo){
            this.redirect({
                    path: 'news',
                    query: {
                        id: this.data.tNo,
                        type:this.data.type,
                        status: this.status,
                        startDate:this.data.startDate,
                        endDate:this.data.endDate,
                        title:this.data.title,
                        sub:this.data.sub,
                        timing:this.data.timing,
                        pcid:this.data.pcid
                    },
                })
        }
    },
    mounted() {},
    methods: {
        handleSave() {
            this.$emit('click:save');
        },
        redirect(link) {
            var parent = this;
            if(this.valid != "expired"){
                setTimeout(() => {
                parent.$router.push(link);
            }, 200);
            }
            
        },
        getImage(){
            const parent = this
            getCouponImage(this.data.tNo).then(function(image_url){
                if(!image_url){
                    setTimeout(function(){
                        parent.getImage()
                    },1000)
                } else {
                    parent.imgSrc = image_url
                }
                
            })
        }
    },
    watch: {
        data() {
            this.getImage()
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.p_item {
    flex-direction: column;
    background: #f8f1ec;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    margin-bottom: 16px;
    overflow: hidden;
    transition: background 0.3s;

    .img_wrapper {
        width: 100%;
    }
    .detail_wrapper {
        width: 100%;
        text-align: left;
        padding: 16px;
        flex-direction: column;

        h5 {
            color: $color_main_first;
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 4px;
        }
        h6 {
            color: $color_main_grey_dark;
            font-size: 16px;
            margin-bottom: 4px;
        }
        .due {
            color: $color_main_grey;
            font-size: 12px;
            line-height: 30px;
            background-image: url('~@/assets/images/ic24ArrowR.svg');
            background-repeat: no-repeat;
            background-position: right center;
            background-size: 8px;
        }
        &.done {
            background-image: url('~@/assets/images/ic_ticket.svg');
            background-repeat: no-repeat;
            background-position: right 15px top 32%;
            .due {
                background: none;
            }
        }
        &.invalid {
            background-image: url('~@/assets/images/ic_ticket_expired.svg');
            background-repeat: no-repeat;
            background-position: right 15px top 32%;
        }
    }

    * {
        font-size: 14px;
        line-height: 1.4;
    }

    &:active {
        background: #e9e0da;
    }
}
</style>
