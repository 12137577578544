<template>
    <LayoutWithNav>
        <BackOnTop :url="url" />
        <PopupCouponExchangeSuccess
            :togglePopup="togglePopup"
            v-model="showSuccessPopup"
        />
        <PopupCouponExchangeDuplicate
            :togglePopup="toggleDuplicatePopup"
            v-model="showDuplicatePopup"
        />
        <PopupCouponExchangeError
            :togglePopup="toggleErrorPopup"
            v-model="showErrorPopup"
        />
        <h2>優惠票券</h2>
        <div
            class="d-flex"
            style="margin-left: 16px; margin-right: 16px; margin-top: 5px"
        >
            <FancyInput
                v-model="coupon_code"
                width="380"
                placeholder="輸入優惠序號"
                style="margin-right: 16px"
            />
            <FancyButton
                class="btn"
                width="70"
                height="48"
                label="確定"
                @click="exchangeCoupon"
            />
        </div>
        <div class="info_wrappper">
            <Tab
                :tabList="tabList"
                :currentTab="currentTab"
                @update="tabHandler"
            />
            <div class="unget_list list" v-show="currentTab === 'unget'">
                <CouponItem
                    v-for="(el, idx) in couponUngetList"
                    :key="idx"
                    :data="el"
                    status="counting"
                ></CouponItem>
            </div>
            <div class="undone_list list" v-show="currentTab === 'undone'">
                <CouponItem
                    v-for="(el, idx) in couponUndoneList"
                    :key="idx"
                    :data="el"
                    status="getCoupon"
                    :valid="el.valid"
                ></CouponItem>
            </div>
            <div class="done_list list" v-show="currentTab === 'done'">
                <CouponItem
                    v-for="(el, idx) in couponDoneList"
                    :key="idx"
                    :data="el"
                    status="redeemed"
                    :valid="el.valid"
                ></CouponItem>
            </div>
        </div>
    </LayoutWithNav>
</template>

<script>
import BackOnTop from '@/components/BackOnTop.vue';
import LayoutWithNav from '@/layouts/LayoutWithNav.vue';
import Tab from '@/components/Tab.vue';
import CouponItem from '@/components/CouponItem.vue';
import PopupCouponExchangeSuccess from '@/components/Popup/PopupCouponExchangeSuccess.vue';
import PopupCouponExchangeDuplicate from '@/components/Popup/PopupCouponExchangeDuplicate.vue';
import PopupCouponExchangeError from '@/components/Popup/PopupCouponExchangeError.vue';
import FancyInput from '@/components/FancyInput.vue';
import FancyButton from '@/components/FancyButton.vue';
import {
    getCouponList,
    getPersonalCouponList,
    useCodeGetCoupon,
} from '@/apis/api.js';
export default {
    name: 'CouponIndex',
    components: {
        BackOnTop,
        LayoutWithNav,
        Tab,
        CouponItem,
        FancyInput,
        FancyButton,
        PopupCouponExchangeSuccess,
        PopupCouponExchangeDuplicate,
        PopupCouponExchangeError,
    },
    data: () => ({
        showSuccessPopup: false,
        showDuplicatePopup: false,
        showErrorPopup: false,
        coupon_code: '',
        tabList: [
            {
                name: '未領取',
                value: 'unget',
            },
            {
                name: '未使用',
                value: 'undone',
            },
            {
                name: '已兌換',
                value: 'done',
            },
        ],
        currentTab: 'unget',
        couponUngetList: [],
        couponUndoneList: [],
        couponDoneList: [],
        url: '/',
    }),
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    created() {
        if (
            !localStorage.getItem('phone') ||
            !localStorage.getItem('password') ||
            !localStorage.getItem('member_no')
        ) {
            this.$router.push('/login');
        } else {
            if (this.$route.query.type == 'use') this.currentTab = 'undone';
            else if (this.$route.query.type == 'unget')
                this.currentTab = 'unget';
            else if (this.$route.query.type == 'done') this.currentTab = 'done';

            this.getCouponList();
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.$store.state.isLoading = false;
        });
    },
    methods: {
        getCouponList() {
            let parent = this;
            var newDateObj = new Date();
            var today =
                newDateObj.getFullYear().toString() +
                (newDateObj.getMonth() + 1 < 10
                    ? '0' + (newDateObj.getMonth() + 1).toString()
                    : newDateObj.getMonth() + 1
                ).toString() +
                (newDateObj.getDate() < 10
                    ? ('0' + newDateObj.getDate()).toString()
                    : newDateObj.getDate()
                ).toString();

            getCouponList(
                localStorage.getItem('phone'),
                localStorage.getItem('password')
            ).then(function (all_coupon_list) {
                if (all_coupon_list.length && all_coupon_list) {
                    parent.couponUngetList = [];
                    parent.couponUndoneList = [];
                    parent.couponDoneList = [];
                    getPersonalCouponList(
                        localStorage.getItem('phone'),
                        localStorage.getItem('password')
                    ).then(function (personal_coupon_list) {
                        console.log(all_coupon_list, personal_coupon_list);
                        for (let i in all_coupon_list) {
                            for (let j in personal_coupon_list) {
                                if (
                                    all_coupon_list[i].tNo ==
                                    personal_coupon_list[j].tNo
                                ) {
                                    all_coupon_list[i].endDate =
                                        personal_coupon_list[j].endDate;
                                }
                            }
                        }
                        let per_check_index = [];
                        for (let i in all_coupon_list) {
                            var endDateTime = new Date(
                                all_coupon_list[i].endDate.slice(0, 4) +
                                    '/' +
                                    all_coupon_list[i].endDate.slice(4, 6) +
                                    '/' +
                                    all_coupon_list[i].endDate.slice(6, 8)
                            ).getTime();
                            var nowDateTime = new Date().getTime();
                            var expired_days =
                                (nowDateTime - endDateTime) /
                                (1000 * 60 * 60 * 24);
                            console.log(all_coupon_list[i].title, expired_days);
                            if (
                                all_coupon_list[i].startDate <= today &&
                                all_coupon_list[i].endDate >= today
                            ) {
                                var have = false;
                                all_coupon_list[i].type = '優惠票卷';
                                for (let j in personal_coupon_list) {
                                    if (
                                        per_check_index.indexOf(j) == -1 &&
                                        all_coupon_list[i].tNo ==
                                            personal_coupon_list[j].tNo
                                    ) {
                                        have = true;
                                        per_check_index.push(j);
                                        console.log(
                                            'per_check_index',
                                            per_check_index
                                        );
                                        if (
                                            personal_coupon_list[j].isUse == '1'
                                        ) {
                                            all_coupon_list[i].endDate =
                                                personal_coupon_list[j].endDate;
                                            parent.couponDoneList.push(
                                                all_coupon_list[i]
                                            );
                                        } else {
                                            console.log('match', j);
                                            all_coupon_list[i].endDate =
                                                personal_coupon_list[j].endDate;
                                            all_coupon_list[i].pcid = personal_coupon_list[j].pcid;
                                            let temp_coupon = JSON.parse(
                                                JSON.stringify(
                                                    all_coupon_list[i]
                                                )
                                            );
                                            temp_coupon.index = j;
                                            parent.couponUndoneList.push(
                                                temp_coupon
                                            );
                                        }
                                    }
                                    if (j == personal_coupon_list.length - 1) {
                                        if (
                                            !have &&
                                            all_coupon_list[i].isvisible !=
                                                'False'
                                        )
                                            parent.couponUngetList.push(
                                                all_coupon_list[i]
                                            );
                                    }
                                }
                                if (
                                    (!personal_coupon_list.length ||
                                        !personal_coupon_list) &&
                                    all_coupon_list[i].isvisible != 'False'
                                )
                                    parent.couponUngetList.push(
                                        all_coupon_list[i]
                                    );
                            } else if (all_coupon_list[i].endDate < today) {
                                have = false;
                                all_coupon_list[i].type = '優惠票卷';
                                for (let j in personal_coupon_list) {
                                    if (
                                        all_coupon_list[i].tNo ==
                                        personal_coupon_list[j].tNo
                                    ) {
                                        if (
                                            personal_coupon_list[j].isUse == '1'
                                        ) {
                                            // 領取並用過且過期
                                            have = true;
                                            all_coupon_list[i].valid = '';
                                            all_coupon_list[i].endDate =
                                                personal_coupon_list[j].endDate;
                                            parent.couponDoneList.push(
                                                all_coupon_list[i]
                                            );
                                        } else {
                                            // 領取沒用過且過期
                                            have = true;
                                            all_coupon_list[i].valid =
                                                'expired';
                                            all_coupon_list[i].endDate =
                                                personal_coupon_list[j].endDate;
                                            if (expired_days < 30)
                                                parent.couponDoneList.push(
                                                    all_coupon_list[i]
                                                );
                                        }
                                    }
                                    if (j == personal_coupon_list.length - 1) {
                                        if (
                                            !have &&
                                            all_coupon_list[i].isvisible !=
                                                'False'
                                        ) {
                                            // 未領取且過期
                                            all_coupon_list[i].valid =
                                                'expired';
                                            if (expired_days < 30)
                                                parent.couponDoneList.push(
                                                    all_coupon_list[i]
                                                );
                                        }
                                    }
                                }
                                if (
                                    (!personal_coupon_list.length ||
                                        !personal_coupon_list) &&
                                    all_coupon_list[i].isvisible != 'False'
                                ) {
                                    all_coupon_list[i].valid = 'expired';
                                    if (expired_days < 30)
                                        parent.couponDoneList.push(
                                            all_coupon_list[i]
                                        );
                                }
                            }
                            if (i == all_coupon_list.length - 1) {
                                // setTimeout(function(){
                                parent.couponUngetList.sort(function (a, b) {
                                    return (
                                        b.create_time
                                            .replace('T', '')
                                            .replace(/:/g, '') -
                                        a.create_time
                                            .replace('T', '')
                                            .replace(/:/g, '')
                                    );
                                });
                                parent.couponUndoneList = JSON.parse(
                                    JSON.stringify(parent.couponUndoneList)
                                ).sort(function (a, b) {
                                    return (
                                        parseInt(b.index) - parseInt(a.index)
                                    );
                                });
                                console.log(
                                    'parent.couponUndoneList',
                                    parent.couponUndoneList
                                );
                                parent.couponDoneList.sort(function (a, b) {
                                    return (
                                        b.create_time
                                            .replace('T', '')
                                            .replace(/:/g, '') -
                                        a.create_time
                                            .replace('T', '')
                                            .replace(/:/g, '')
                                    );
                                });
                                // },1000)
                            }
                        }
                    });
                }
            });
        },
        togglePopup(mode) {
            this.showSuccessPopup = false;
            if (mode == 'undone') {
                this.tabHandler('undone');
            }
        },
        toggleDuplicatePopup(mode) {
            this.showDuplicatePopup = false;
            if (mode == 'undone') {
                this.tabHandler('undone');
            }
        },
        toggleErrorPopup() {
            this.showErrorPopup = false;
        },
        exchangeCoupon() {
            console.log('exchangeCoupon');
            const parent = this;
            // 兌換
            if (this.coupon_code) {
                useCodeGetCoupon(
                    localStorage.getItem('phone'),
                    localStorage.getItem('password'),
                    this.coupon_code
                ).then(function (rsp) {
                    console.log(rsp);
                    rsp = JSON.parse(rsp);
                    if (rsp.status === '0') {
                        // success???
                        parent.getCouponList();
                        parent.showSuccessPopup = true;
                    } else if (
                        rsp.status === '1' &&
                        rsp.message.match('already') != null
                    ) {
                        parent.showDuplicatePopup = true;
                    } else {
                        parent.showErrorPopup = true;
                    }
                });
            } else {
                parent.showErrorPopup = true;
            }
        },
        tabHandler(value) {
            this.currentTab = value;
        },
    },
};
</script>
<style lang="scss" scoped>
h2 {
    color: $color_main_first;
    font-size: 26px;
    font-weight: bold;
    padding: 12px 16px;
    line-height: 1;
    text-align: left;
}
.list {
    margin-top: 12px;
    padding: 0 16px 70px;
}
</style>
