<template>
    <FancyDialog v-bind="{ ...$attrs }" v-on="$listeners">
        <v-container fluid class="dialog_container">
            <img
                width="90"
                height="90"
                class="mb-1"
                src="@/assets/images/imgWarning.svg"
                alt=""
            />
            <p class="title mb-3">代碼輸入失敗</p>
            <p class="desc mb-3">輸入的代碼無效<br>請重新輸入</p>
            <div class="footer d-flex justify-center">
                <FancyButton
                    class="btn"
                    label="確定"
                    @click="togglePopup('')"
                />
            </div>
        </v-container>
    </FancyDialog>
</template>

<script>
import FancyDialog from '@/components/FancyDialog.vue';
import FancyButton from '@/components/FancyButton.vue';
export default {
    components: {
        FancyDialog,
        FancyButton,
    },
    name: 'PopupCouponExchangeSuccess',
    props: {
        sub: {
            type: String
        },
        togglePopup:{
            type: Function
        },
    },
    emits: ['input', 'click:confirm'],
    data: () => ({}),
};
</script>

<style lang="scss" scoped>
div.dialog_container {
    p.title {
        color: $color_main_first;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        letter-spacing: -0.3px;
    }
    p.desc {
        font-size: 14px;
        line-height: 24px;
        color: $color_main_black;
    }
    > .footer {
        .btn {
            height: 50px;
            width: 100%;
            @media screen and (max-width: 374px) {
                max-width: 118px;
            }
            @media screen and (min-width: 375px) {
                max-width: 145.5px;
            }
        }
    }
}
</style>
