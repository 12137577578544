var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p_item d_flex"},[_c('div',{staticClass:"img_wrapper"},[_c('img',{attrs:{"src":_vm.data.imgSrc ? _vm.data.imgSrc : _vm.imgSrc,"alt":""}})]),_c('div',{staticClass:"detail_wrapper d_flex",class:{ done: _vm.status === 'redeemed', invalid: _vm.valid === 'expired' },on:{"click":function($event){return _vm.redirect({
                path: 'news',
                query: {
                    id: _vm.data.tNo,
                    type:_vm.data.type,
                    status: _vm.status,
                    startDate:_vm.data.startDate,
                    endDate:_vm.data.endDate,
                    title:_vm.data.title,
                    sub:_vm.data.sub,
                    timing:_vm.data.timing,
                    pcid:_vm.data.pcid
                },
            })}}},[_c('h5',[_vm._v(_vm._s(_vm.data.title))]),_c('h6',[_vm._v(_vm._s(_vm.data.sub))]),_c('span',{staticClass:"due"},[_vm._v("票券到期日 "+_vm._s([_vm.data.endDate.slice(0,4),_vm.data.endDate.slice(4,6),_vm.data.endDate.slice(6,8)].join("/")))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }